<template>
  <b-form-group
    label="Firma Adı"
    label-for="company_name"
  >
    <validation-provider
      #default="{ errors }"
      name="Firma Adı"
      rules="required"
    >
      <b-form-input
        id="company_name"
        v-model="customer.company_name"
        placeholder="Firma Adı"
        :readonly="isReadonly"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  name: 'CompanyName',
  components: {
    BFormGroup,
    BFormInput,
    ValidationProvider,
  },
  props: {
    isReadonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      locale: 'tr',
    }
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
  },
  created() {
    localize(this.locale)
  },
}
</script>

<style scoped></style>
